import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/przewoznik'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import { useFormContext } from '../context/form.context'
import { SectionBackground } from '../components/Section'
import FormDynamic from '../components/FormDynamic'
import Tiles from '../components/Tiles'

const Przewoznik = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image, imgDocs } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/przewoznik.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      imgDocs: file(absolutePath: { regex: "/images/intros/dokumenty.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Wyślij zgłoszenie',
      en: 'Send request',
    },
    action: () => setFormOpen(true),
  }

  const buttonSecond = {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read More',
    },
    action: 'SCROLL',
  }

  const links = [{ name: 'Dokumenty', image: imgDocs, slug: 'dokumenty' }]

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/przewoznik.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main data={main} h={1} />
      <SectionBackground mobile top bottomXS>
      <FormDynamic notypes override='CARRIER' />
      </SectionBackground>
      <Tiles data={links} marginMinimal />
    </Layout>
  )
}

export default Przewoznik
